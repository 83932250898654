@import '../variables'
@import '../mixins/anchor'

$tagSpacingPixels: 5px
$tagColor: $grey300
$tagBorderColor: $grey100
$tagHoverBorderColor: $grey300

.content-heading
  font-size: 14px
  padding-bottom: 40px

.content-heading__primary
  padding-top: 40px
  padding-bottom: 32px
  border-top: solid 1px $borderColor
  border-bottom: solid 1px $borderColor
  +mobile
    padding-top: 24px

.content-heading__likes-container
  padding-left: 5px
  display: inline-block
  .content-heading__likes-icon
    color: darkgrey
    padding-right: 2px
  .content-heading__likes-title
    +mobile
      display: none

+non-mobile
  .content-heading__primary
    display: table
    width: 100%
  .content-heading__primary-authorship
    display: table-cell
    +anchor-link-style
  .content-heading__primary-attributes
    display: table-cell
    text-align: right

.content-heading__item
  margin-right: 10px
  &:last-child
    margin-right: 0

.content-heading__label
  color: #8396A0
  margin-right: 4px

.content-heading__value
  color: $grey700

.content-heading__publication-date
  padding-left: 10px

+non-mobile
  .content-heading__secondary
    display: table
    width: 100%
  .content-heading__secondary-categories
    display: table-cell

.content-heading__secondary
  padding-top: 32px
  padding-bottom: 40px
  border-bottom: solid 1px $grey50

.content-heading__secondary-categories-tag
  display: inline-block
  margin-top: $tagSpacingPixels
  margin-right: 4px
  padding: 0 4px
  color: $tagColor
  border: 2px solid $tagBorderColor
  border-radius: 6px
  white-space: nowrap
  &:hover, &:focus, &:active
    border-color: $tagHoverBorderColor

.content-heading
  .content-heading__secondary
    display: none
    +non-mobile
      display: table

  &.content-heading__mobile
    display: none
    margin: 40px 0px -56px
    +mobile
      display: block
    .content-heading__secondary
      border-top: solid 1px $grey50
      display: block
