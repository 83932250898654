@import '../../variables'

.courses-index-header__title-row
  margin-bottom: 0
  line-height: 1em
  font: $font

.courses-index-header__promo-banner
  margin-bottom: 20px

.courses-index-header__promo-banner-image
  width: 100%

.courses-index-header__title
  display: inline-block
  float: left

.courses-index-header__title-controls
  float: right
  margin-top: 18px

.courses-index-header__filter-group
  display: inline-block

.courses-index-header__topic-title
  margin-top: 5px
  display: inline-block
  font-size: 30px

.courses-index-header__title-controls-label
  font-style: italic
  color: #90a6b3
  font-size: 12px
  display: block

.courses-index-header__button-list
  padding: 0
  display: flex

.courses-index-header__filtering-button-item
  list-style: none
  display: inline-block
  background: $white
  color: $newSecondaryBodyTextColour
  border-right: 0px
  font: $font
  font-size: 12px

  &:first-child
    border-top-left-radius: .5em
    border-bottom-left-radius: .5em
    margin-right: 1px
  &:last-child
    border-top-right-radius: .5em
    border-bottom-right-radius: .5em
    margin-left: 1px
  a
    padding: 5px 10px


.courses-index-header__filtering-button-item-active
  background: #E1E1E1
  font-weight: bold
  a
    color: $grey700

.courses-index-header__filtering-cc-text
  margin-left: 5px
  +desktop
    display: none
  +mobile-and-tablet
    display: none


.courses-index-header__filtering-button-item-inactive
  a
    color: $grey300
  a:hover
    color: $grey700

.courses-index-header__filtering-cc-image
  width: 16px
  position: relative
  top: 2px
